import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import NewLoansCard from "@shared/components/NewLoansCard";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { printAmount } from "@shared/functions";
import { getNewLoansSelector } from "../context/Selectors";
import DateRangePicker from "@shared/components/DateRangePicker";
// import BottomNavigation from "../components/BottomNavigation";
import { UseEntregas, UseRoute } from "@shared/server/fetcher";
import { UseVerificationDebtors, UseVerificationLoans } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import NoInternetContent from "@shared/components/NoInternetContent";

const NewLoans = ({ history }) => {
  const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { state } = useContext(Context);
  const { route: stateRoute, user, userConfig } = state;
  const [currentDates, setCurrentDates] = useState(null);

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  const { data: newLoans, isLoading } = UseEntregas(
    route?.creditor_id,
    currentDates?.start_date ?? route?.collect_week_date,
    currentDates?.end_date ?? route?.current_range_date
  );

  const { data: createdDebtors, isLoading: dVer_loading } = UseVerificationDebtors(route?.cuadre_id);
  const { data: createdLoans, isLoading: lVer_loading } = UseVerificationLoans(dVer_loading ? null : route?.cuadre_id);

  useEffect(() => {
    if (route && !currentDates?.start_date) {
      setCurrentDates({
        start_date: route.collect_week_date,
        end_date: route.current_range_date,
      });
    }

    if (newLoans) {
      setCurrentData(getNewLoansSelector(newLoans, false));
    }
    //eslint-disable-next-line
  }, [route, newLoans]);

  if (isLoading || routeLoading) return <Loading />;
  if (!newLoans) return <NoInternetContent />;

  return (
    <>
      {currentData.count === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Entrega de Prestamos!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los nuevos prestamos registrado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no se ha registrado ningun renovo o nuevo prestamo!
          </Typography>
          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} route={route} />
        </div>
      ) : (
        <div className="mt-3 mb-5">
          <Box component="div" p={1}>
            <Typography variant="h6" componet="h4">
              Entrega de Prestamos ({currentData.count}): {printAmount(currentData.amount)}
            </Typography>
          </Box>

          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} route={route} />
          <Grid container>
            {currentData.list.map((loan) => (
              <Grid key={loan.money_id} item xs={12}>
                <NewLoansCard history={history} loan={loan} route={route} user={user} userConfig={userConfig} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {/* <BottomNavigation history={history} /> */}
    </>
  );
};

export default NewLoans;
