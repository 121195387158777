import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "@shared/components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
import NumberFormat from "react-number-format";
import CollectedPaymentMenu from "../components/CollectedPaymentMenu";
import DateRangePicker from "@shared/components/DateRangePicker";
import { Link } from "react-router-dom";
import { RemoveAccents } from "@shared/functions";
import { getCollectedLoans } from "../context/Selectors";
// import BottomNavigation from "../components/BottomNavigation";
import { UseCollected, UseRoute } from "@shared/server/fetcher";
import { UseVerificationDebtors, UseVerificationLoans, UseVerificationPayments } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import NoInternetContent from "@shared/components/NoInternetContent";

const Collected = ({ history }) => {
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { route: stateRoute, user } = state;
  const [currentDates, setCurrentDates] = useState(null);

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);

  const { data: createdDebtors, isLoading: dVer_loading } = UseVerificationDebtors(route?.cuadre_id);
  const { data: createdLoans, isLoading: lVer_loading } = UseVerificationLoans(dVer_loading ? null : route?.cuadre_id);
  const { data: createdPayments } = UseVerificationPayments(dVer_loading || lVer_loading ? null : route?.cuadre_id);

  const {
    data: collected,
    isLoading,
    mutate,
  } = UseCollected(
    route?.creditor_id,
    currentDates?.start_date ?? route?.collect_week_date,
    currentDates?.end_date ?? route?.current_range_date
  );

  useEffect(() => {
    if (route && !currentDates?.start_date) {
      setCurrentDates({
        start_date: route.collect_week_date,
        end_date: route.current_range_date,
      });
    }

    if (collected) {
      setCurrentData(getCollectedLoans(collected, false));
    }
    //eslint-disable-next-line
  }, [route, collected]);

  useEffect(() => {
    if (createdPayments?.length > 0) {
      mutate();
    }

    //eslint-disable-next-line
  }, [createdPayments]);

  if (isLoading || routeLoading) return <Loading />;
  if (!collected) return <NoInternetContent />;

  const getFilteredDebtor = (debtors) => {
    let filtered = debtors;

    if (searchText.length > 2) {
      filtered = filtered.filter((m) => RemoveAccents(m.name).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
    }

    return filtered;
  };

  const loansFilter = getFilteredDebtor(currentData.list);

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <>
      {currentData.count === 0 ? (
        <div className="container mt-4 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Prestamos Cobrado!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los pagos aplicado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no ha aplicado ningun Pago!
          </Typography>
          <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} route={route} />
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              Prestamos cobrado ({currentData.count}):
              <NumberFormat value={currentData.amount} displayType={"text"} thousandSeparator={true} prefix={" $"} />
            </Typography>
            <Typography variant="body1" component="h4">
              Lista de Prestamos cobrado esta semana
            </Typography>

            <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} route={route} />
            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {loansFilter.map((loan) => (
                  <tr key={loan.payment_id}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        <Link style={{ color: "#000" }} to={`/loan/detail/${loan.money_id}/${loan.debtor_id}`}>
                          <PersonIcon /> {loan.name}
                        </Link>
                      </Typography>
                    </td>
                    <td>
                      <NumberFormat
                        value={loan.paymentAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={" $"}
                      />
                    </td>
                    <td>
                      <CollectedPaymentMenu history={history} loan={loan} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* <BottomNavigation history={history} /> */}
    </>
  );
};

export default Collected;
